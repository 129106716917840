import { useState, useEffect } from "react";

import localforage from "localforage";

/**
 * value: T
 *  The first element of the array is the stored value of the key.
 *  Since all the methods exposed by localForage are async, the initial value is
 *  always undefined until the storage has been succesfully read.
 *  If the key is not present on the store, that undefined will change to null.
 *  If there's a value already stored, that value will be returned.
 *  If there's an error, the value will remain as undefined.
 * setValue: (value: T) => void
 *  Persists the value.
 * error: Error
 * isLoading: True during the initial load
 */
const useLocalForage = <T>(key: string, initialValue?: T) => {
  const [value, setValue] = useState<T | null>(initialValue || null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const storedValue = await localforage.getItem<T>(key);
        setValue(storedValue || initialValue || null);
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error(
                `Unknown error when trying to access local storage for ${key}`
              )
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [key, initialValue]);

  const setStoredValue = async (newValue: T) => {
    try {
      setIsLoading(true);
      await localforage.setItem(key, newValue);
      setValue(newValue);
      setError(null);
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error(
              `Unknown error when trying to set local storage for ${key}`
            )
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { value, setValue: setStoredValue, error, isLoading };
};

export { useLocalForage };
